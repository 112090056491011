import _ from "lodash-es";
import Fuse from "fuse.js";
import { Suggestion } from "@/store/types";

function validateSuggestions(payload: any) {
  if (!Array.isArray(payload)) {
    return false;
  }

  const isValidPayload = payload.every((item) => {
    const validKeys = ["value", "keywords", "language"];
    const keys = Object.keys(item);

    const isValidProperty = _.isEqual(validKeys, keys);
    if (!isValidProperty) {
      return false;
    }

    if (!Array.isArray(item.keywords)) {
      return false;
    }

    const keywordsArrayOfString = item.keywords.every(
      (keyword: string) => typeof keyword === "string"
    );

    if (!keywordsArrayOfString) {
      return false;
    }

    return true;
  });

  return isValidPayload;
}

export default function initiateFuseInstance(payload: any): Fuse<Suggestion> {
  const isValidPayload = validateSuggestions(payload);
  const validPayload = isValidPayload ? payload : [];

  return new Fuse<Suggestion>(validPayload, {
    shouldSort: true,
    threshold: 0.3, // lower value means more strict
    useExtendedSearch: true,
    location: 0,
    distance: 100,
    ignoreLocation: true,
    minMatchCharLength: 1,
    keys: [
      {
        name: "value",
        weight: 0.9,
      },
      {
        name: "keywords", // keywords are tokens from intent name
        weight: 0.1,
      },
    ],
  });
}
