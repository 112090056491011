<template>
  <div
    :ref="facebook.ref"
    :page_id="facebook.page_id"
    :data-href="facebook['data-href']"
    :messenger_app_id="facebook.messenger_app_id"
    :theme_color="facebook.theme_color"
    class="fb-customerchat"
  />
</template>

<script>
export default {
  props: {
    facebook: {
      type: Object,
      default() {
        return {
          page_id: "",
          messenger_app_id: "",
          "data-href": "https://keyreply.com",
          ref: location.hostname,
          // theme_color: "#E4392B",
        };
      },
    },
  },
  mounted() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.facebook.messenger_app_id,
        xfbml: true,
        version: "v2.12",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  methods: {
    show() {
      window.FB.CustomerChat.showDialog();
    },
    hide() {
      window.FB.CustomerChat.hideDialog();
    },
  },
};
</script>
<!--
<style>
#fb-root .fb_dialog {
  visibility: hidden !important;
}

#fb-root iframe[kwframeid="2"] {
  bottom: 10pt !important;
  right: 50pt !important;
}
</style>
-->

<!-- Css with RTL generated by script -->
<style>
#fb-root .fb_dialog {
  visibility: hidden !important;
}
/*@noflip*/
html[dir="ltr"] #fb-root iframe[kwframeid="2"] {
  bottom: 10pt !important;
  right: 50pt !important;
}
/*@noflip*/
html[dir="rtl"] #fb-root iframe[kwframeid="2"] {
  bottom: 10pt !important;
  left: 50pt !important;
}
</style>
