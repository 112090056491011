<template>
  <el-form
    ref="prechatform"
    :model="prechatFormFieldDetails"
    :rules="formRules"
    style="padding: 15px"
  >
    <span class="text-field">{{ prechatFormSettings.greeting }}</span>

    <el-divider>
      <!-- <i style="color: #616161;">User Details</i> -->
    </el-divider>

    <el-form-item
      v-if="prechatFormSettings.promptTopic.enabled"
      label="What do you wish to enquire about?"
      prop="chosenTopic"
    >
      <el-select
        v-model="prechatFormFieldDetails.chosenTopic"
        placeholder="Please select your topic"
        filterable
      >
        <el-option
          v-for="(item, index) in prechatFormSettings.promptTopic.topics"
          class="topics-select"
          :key="`topic-option-${index}`"
          :label="capitalize(item)"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-if="prechatFormSettings.promptName" label="Name" prop="name">
      <el-input v-model="prechatFormFieldDetails.name"></el-input>
    </el-form-item>

    <el-form-item v-if="prechatFormSettings.promptEmail" label="Email" prop="email">
      <el-input v-model="prechatFormFieldDetails.email"></el-input>
    </el-form-item>

    <el-form-item v-if="prechatFormSettings.promptNumber" label="Phone Number" prop="number">
      <el-input v-model="prechatFormFieldDetails.number"></el-input>
    </el-form-item>

    <el-form-item v-if="prechatFormSettings.termsAndConditions.enabled" prop="acceptTnC">
      <el-checkbox v-model="prechatFormFieldDetails.acceptTnC">
        I accept the
        <a :href="prechatFormSettings.termsAndConditions.url">Terms & Conditions</a>
      </el-checkbox>
    </el-form-item>

    <el-form-item v-if="prechatFormSettings.privacyPolicy.enabled" prop="acceptPrivacyPolicy">
      <el-checkbox v-model="prechatFormFieldDetails.acceptPrivacyPolicy">
        I accept the
        <a :href="prechatFormSettings.privacyPolicy.url">Privacy Policy</a>
      </el-checkbox>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        size="mini"
        style="width: 100%"
        @click="submitPreChatForm('prechatform')"
        >Start Chat</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import _ from "lodash-es";
export default {
  name: "PreChatForm",
  props: ["settings"],
  data() {
    const validateTopic = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please select a topic"));
      } else {
        callback();
      }
    };

    const validateEmail = (rule, value, callback) => {
      const EMAILREGEX =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      const isEmail = EMAILREGEX.test(value);
      if (value === "") {
        callback(new Error("Email is required"));
      } else if (!isEmail) {
        callback(new Error("Please enter a valid Email address"));
      } else {
        callback();
      }
    };

    const validateNumber = (rule, value, callback) => {
      const PHONEREGEX = /^[0-9]+$/;
      const isPhoneNumber = PHONEREGEX.test(value);
      if (value === "") {
        callback(new Error("Number is required"));
      } else if (!isPhoneNumber) {
        callback(new Error("Please enter a valid phone number"));
      } else {
        callback();
      }
    };

    const validateTnC = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please accept the Terms & Conditions"));
      } else {
        callback();
      }
    };

    const validatePrivacyPolicy = (rule, value, callback) => {
      if (!value) {
        callback(new Error("Please accept the Privacy Policy"));
      } else {
        callback();
      }
    };

    return {
      prechatFormSettings: {
        greeting: "",
        promptEmail: false,
        promptName: false,
        promptNumber: false,
        termsAndConditions: {
          enabled: false,
          url: "",
        },
        privacyPolicy: {
          enabled: false,
          url: "",
        },
        promptTopic: {
          enabled: false,
          topics: [],
        },
      },
      prechatFormFieldDetails: {
        name: "",
        email: "",
        number: "",
        acceptTnC: false,
        acceptPrivacyPolicy: false,
        chosenTopic: "",
      },
      formRules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            validator: validateNumber,
            trigger: "blur",
          },
        ],
        acceptTnC: [
          {
            required: true,
            validator: validateTnC,
            trigger: "change",
          },
        ],
        acceptPrivacyPolicy: [
          {
            required: true,
            validator: validatePrivacyPolicy,
            trigger: "change",
          },
        ],
        chosenTopic: [
          {
            required: true,
            validator: validateTopic,
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.getPreChatFormSettings();
  },

  methods: {
    capitalize(text) {
      return _.startCase(text);
    },

    getPreChatFormSettings() {
      this.$store
        .dispatch("GET_PRECHATFORM_SETTINGS")
        .then((result) => {
          if (_.isEmpty(result)) return;

          this.prechatFormSettings = result;

          const noGreeting = !_.get(this, "prechatFormSettings.greeting");
          if (noGreeting) {
            this.$emit("setShowPreChatForm", false);
          }
        })
        .catch((error) => {
          this.$emit("setShowPreChatForm", false);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Error getting form settings",
          });
        });
    },

    validate(formName) {
      let isValidForm = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isValidForm = valid;
        }
      });
      return isValidForm;
    },

    async submitPreChatForm(formName) {
      const isValidForm = this.validate(formName);
      if (!isValidForm) return;

      const { name, email, number, acceptTnC, acceptPrivacyPolicy, chosenTopic } =
        this.prechatFormFieldDetails;

      const form = {
        name,
        email,
        number,
        acceptTnC,
        acceptPrivacyPolicy,
        chosenTopic,
      };

      await this.$store.dispatch("LOGOUT");
      await this.$store.dispatch("REGISTER");
      await this.$store.dispatch("LOGIN");

      try {
        const result = await this.$store.dispatch("SEND_PRECHATFORM_DETAILS", form);
        const success = _.get(result, "success", false);
        if (success) {
          this.$emit("setShowPreChatForm", !isValidForm);
          this.$emit("onPreChatFormSuccess", {
            isValid: isValidForm,
            formData: form,
          });
        }
      } catch (e) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: "Error sending form details",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-field {
  color: #606266;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.topics-select {
  font-family: "ProximaNova", "Tahoma", "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.text-capitalize {
  text-transform: capitalize;
}

.el-select {
  width: 100%;
}
</style>
