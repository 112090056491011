<template>
  <div class="trending" :style="style.top" v-if="trendingTopics.length > 0">
    <div class="trending-button">
      <div
        class="trending-button-toggle"
        :style="styleBanner()"
        @click="handleTrendingList"
        style="cursor: pointer"
      >
        <p>Trending Topics</p>
        <div
          :class="[{ active: isOpenList }]"
          style="display: flex; align-items: center"
          :style="style.marginRight"
        >
          <DropDownCustom :fill="settings.trendingTopicTitle" />
        </div>
      </div>
      <div class="trending-button-list" v-if="isOpenList">
        <div
          class="trending-button-list-item"
          v-for="(trending, i) in listTopicFilter"
          :key="i"
          @click="submitTrendingIntent(trending)"
        >
          <div>
            <CursorCustom :fill="settings.buttonOutlineColor" />
          </div>
          <p style="color: black">
            {{ getExampleTrendingTopic(trending) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getNodeIdByLanguage, getTextButton, getExampleByLanguage } from "@/helpers/trendingTopics";
import _ from "lodash-es";
import CursorCustom from "@/assets/images/CursorCustom.vue";
import DropDownCustom from "@/assets/images/DropDownCustom.vue";
const getters = mapGetters({
  trendingTopics: "trendingTopics",
  settings: "settings",
  currentLanguage: "currentLanguage",
});

export default {
  components: { CursorCustom, DropDownCustom },
  props: ["isOpenList"],
  data() {
    return {
      listTopicFilter: [],
      heightHeader: 0,
      style: {
        top: "",
        marginRight: "",
      },
    };
  },
  mounted() {
    const elementHeader = document.getElementById("keyreply-panel-header");
    if (elementHeader) {
      this.heightHeader = elementHeader.offsetHeight;
    }
    this.style.top = `top : ${this.heightHeader}px`;
    if (this.heightHeader === 0) {
      this.style.marginRight = `margin-right: 45px`;
    }
  },
  methods: {
    getExampleTrendingTopic(trending) {
      const { intentId, examples } = trending;
      if (!this.currentLanguage) return getTextButton(intentId);
      const example = getExampleByLanguage(this.currentLanguage, examples);
      if (example) return example;
      return "";
    },
    submitTrendingIntent(trending) {
      const { intentId, examples } = trending;
      const nodeId = getNodeIdByLanguage(this.currentLanguage, examples);
      this.handleTrendingList(false);
      const trendingText = getExampleByLanguage(this.currentLanguage, examples);
      const button = JSON.parse(intentId);
      if (trendingText && nodeId) {
        button.data = nodeId;
      }
      this.$emit("sendPostback", { ...button, trendingText });
    },
    handleTrendingList(isOpen = false) {
      this.$emit("handleTrendingList", false);
    },
    styleBanner() {
      return {
        color: this.settings?.trendingTopicTitle || "black",
        background: this.settings?.trendingTopicBanner || "gray",
      };
    },
    styleTitle() {
      return {
        color: this.settings?.trendingTopicTitle || "black",
      };
    },
    getListTopicFilter() {
      this.listTopicFilter = this.trendingTopics.filter((data) =>
        this.getExampleTrendingTopic(data)
      );
    },
  },
  computed: {
    ...getters,
  },
  async created() {
    await this.$store.dispatch("FETCH_TRENDING_TOPICS");
    this.getListTopicFilter();
  },
  watch: {
    isOpenList: {
      async handler() {
        if (this.isOpenList) {
          await this.$store.dispatch("FETCH_TRENDING_TOPICS");
          this.getListTopicFilter();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.trending {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background: white;
  z-index: -1;
  cursor: auto;
  &-button {
    &-toggle {
      color: black;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      transition: 0.3s all linear;
      align-items: center;
      > p {
        margin: 0;
        font-weight: 600;
      }
      > div {
        &.active {
          transform: rotateZ(180deg);
        }
      }
    }
    &-list {
      transition: 0.3s all linear;
      background: white;
      height: calc(100vh - 15px);
      margin-top: 15px;
      &-item {
        padding: 10px;
        display: flex;
        align-items: center;
        &:hover {
          background: #80808036;
          cursor: pointer;
        }
        > div {
          width: 20px;
          transform: rotate(90deg);
          margin-right: 15px;
        }
        > p {
          margin: 0;
        }
      }
    }
  }
}
</style>
