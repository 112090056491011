import _ from "lodash-es";

export default function isDisabledButton(that: any, button: any) {
  const disableButtonReuse: boolean = _.get(that, "settings.disableButtonReuse");
  const messages = _.get(that, "$store.getters.messages", []);
  const isSendingPostback: boolean = _.get(that, "$store.getters.isSendingPostback");
  const shouldDisableIfInLivechat: boolean = that.shouldDisableIfInLivechat;
  const replyMessages = messages.filter((message: any) => message.type === "reply");

  const isLastMessage = that.message.id === (_.last(replyMessages) as any).id;
  if (!disableButtonReuse || isLastMessage) {
    return false;
  }

  // Match any livechat start trigger
  const isHandoverLivechatStart = JSON.stringify(button).match(
    /(handover_livechat_start|handover_router)/
  );

  // Match any livechat stop trigger
  const isHandoverLivechatStop = JSON.stringify(button).match(/handover_stop/);

  const isDisabled: boolean =
    isSendingPostback ||
    !!isHandoverLivechatStart ||
    (shouldDisableIfInLivechat && !isHandoverLivechatStop);

  return isDisabled;
}
