import * as Sentry from "@sentry/browser";

const loggedError: Record<string, number> = {};
export const handleWebsocketError = (error: Error): void => {
  if (!loggedError[error.toString()]) {
    Sentry.captureException(error);
    loggedError[error.toString()] = 1;
  }
  loggedError[error.toString()] += 1;
};

export const FAILED_MESSAGE_ERROR_TEXT = "Failed to send message. Click to send again!";
