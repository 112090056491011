<template>
  <div id="keyreply-badge" class>
    <a></a>
  </div>
</template>

<script>
export default {
  name: "badge",
};
</script>

<style>
/*@noflip*/
html[dir="ltr"] #keyreply-badge {
  position: absolute;
  top: -2px;
  left: -13px;
}
/*@noflip*/
html[dir="rtl"] #keyreply-badge {
  position: absolute;
  top: -2px;
  right: -13px;
}
/*@noflip*/
html[dir="ltr"] .keyreply-badge-num {
  padding-top: 1.5px;
  padding-right: 1.9px;
  box-sizing: border-box;
  font-family: "Trebuchet MS", sans-serif;
  background: #ff0000;
  cursor: default;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  height: 1.8rem;
  width: 1.8rem;
  letter-spacing: -0.1rem;
  line-height: 1.55;
  margin-top: -1rem;
  margin-left: 0.1rem;
  border: 0.2rem solid #fff;
  text-align: center;
  display: inline-block;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  animation: pulse 1.5s 1;
}
/*@noflip*/
html[dir="rtl"] .keyreply-badge-num {
  padding-top: 1.5px;
  padding-left: 1.9px;
  box-sizing: border-box;
  font-family: "Trebuchet MS", sans-serif;
  background: #ff0000;
  cursor: default;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  height: 1.8rem;
  width: 1.8rem;
  letter-spacing: -0.1rem;
  line-height: 1.55;
  margin-top: -1rem;
  margin-right: 0.1rem;
  border: 0.2rem solid #fff;
  text-align: center;
  display: inline-block;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  animation: pulse 1.5s 1;
}
/*@noflip*/
html[dir="ltr"] .keyreply-badge-num:after {
  content: "";
  position: absolute;
  top: -0.1rem;
  left: -0.1rem;
  border: 2px solid rgba(255, 0, 0, 0.5);
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s 1;
}
/*@noflip*/
html[dir="rtl"] .keyreply-badge-num:after {
  content: "";
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
  border: 2px solid rgba(255, 0, 0, 0.5);
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s 1;
}
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
