export default function (payload: string | { [key: string]: any } | null) {
  const parsed = JSON.parse(JSON.stringify(payload));
  const payloadExistAndAnObject = !!parsed && typeof parsed === "object";
  if (payloadExistAndAnObject) {
    delete parsed.iat;
    delete parsed.exp;
  }

  return parsed;
}
