var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.text)?_c('div',{staticClass:"bubble-wrapper",style:({
    justifyContent: _vm.isUserMessage ? 'flex-end' : 'flex-start',
  })},[_c('div',{staticClass:"message-wrapper",class:{
      'message-bubble-left': !_vm.isUserMessage,
      'message-bubble-right': _vm.isUserMessage,
    },style:(_vm.bubbleStyle)},[_c('p',{staticStyle:{"margin-bottom":"0 !important","word-break":"break-word"},domProps:{"innerHTML":_vm._s(_vm.markedText)}}),(_vm.hasSelection)?[_c('DropdownSelection',{attrs:{"selections":_vm.message.data.selections,"button-outline":_vm.buttonOutline,"should-disable-if-in-livechat":_vm.shouldDisableIfInLivechat},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}})]:_vm._e(),(_vm.hasButton)?[_c('br'),_c('MessageButtons',{attrs:{"message":_vm.message,"button-outline":_vm.buttonOutline,"should-disable-if-in-livechat":_vm.shouldDisableIfInLivechat},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}})]:_vm._e(),(_vm.hasLearningFeedback)?[_c('MessageFeedbacks',{attrs:{"message":_vm.message},on:{"sendPostback":function($event){return _vm.$emit('sendPostback', $event)}}})]:_vm._e(),_c('div',{staticStyle:{"text-align":"right"}},[(!_vm.isUiBetaEnabled)?_c('i',{staticStyle:{"font-size":"9px !important"},domProps:{"innerHTML":_vm._s(_vm.timestamp(_vm.message))}}):_c('span',{style:(`font-size: 10px !important; color:${
          _vm.isUserMessage ? this.settings.userTextColor : this.settings.agentTextColor
        } `),domProps:{"innerHTML":_vm._s(_vm.timestamp(_vm.message))}})])],2),_c('ResendMessageButton',{attrs:{"isVisible":_vm.isUserMessage && !_vm.isMessageSent,"resendMessage":_vm.resendMessage,"isDisabled":_vm.isResendButtonDisabled,"errorMessage":_vm.message.errorMessage}}),(_vm.isVideo)?_c('div',{staticClass:"btn-video-expand",on:{"click":function($event){return _vm.$emit('handleExpandVideo', _vm.message)}}},[_c('i',{staticClass:"el-icon-video-play"})]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }