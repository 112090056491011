import Push from "push.js";

export default function (
  title: string,
  body: string,
  icon: string | undefined,
  onClick: Function,
  onError: Function = () => {}
) {
  Push.create(title, { body, icon, onClick, onError }).catch((error) => {
    onError(error);
  });
}
