export default function (num: number) {
  const badge = document.getElementById("keyreply-badge");
  if (badge) {
    let badgeNum;
    const badgeChild = badge.children[0];
    if (badgeChild.className === "keyreply-badge-num") badge.removeChild(badge.children[0]);

    if (num > 0) {
      badgeNum = document.createElement("div");
      badgeNum.setAttribute("class", "keyreply-badge-num");
      badgeNum.innerText = `${num}`;
      badge.insertBefore(badgeNum, badge.firstChild);
    }
  }
}
