<template>
  <el-row type="flex" style="margin: 0 16px 16px 16px; flex-flow: row wrap">
    <el-button
      v-for="(reply, qrindex) in message.data.quickReplies"
      v-text="reply.text"
      size="mini"
      target="_blank"
      :style="{
        fontFamily: settings.font,
      }"
      :key="qrindex"
      :disabled="isDisabledButton(reply)"
      :type="buttonOutline"
      :plain="!reply.active"
      @click.stop.prevent.native="sendPostback(reply)"
      :class="{ 'blue-button': buttonOutline === 'primary' }"
    />
  </el-row>
</template>

<script>
import _ from "lodash-es";
import isDisabledButton from "@/helpers/isDisabledButton";

export default {
  props: ["message", "buttonOutline", "shouldDisableIfInLivechat"],
  methods: {
    sendPostback(reply) {
      this.$emit("sendPostback", reply);
      this.$forceUpdate();
    },
    isDisabledButton(button) {
      return isDisabledButton(this, button);
    },
  },
  computed: {
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  margin: 0 5px 5px 0;
  // flex: 1 1 48%;
  border-radius: 25px;
  &.active {
    background: transparent;
  }
}
</style>

<style lang="scss" scoped>
.ui-beta {
  .el-button {
    margin: 0 5px 5px 0;
    border-radius: 8px;
    white-space: normal;
    text-align: left;

    &.blue-button {
      color: #4e6cce;
      background: #dce2f5;
      border-color: #4e6cce;

      &:hover {
        background: #4e6cce;
        color: #fff;
      }
    }

    &.active {
      background: transparent;
    }
  }
}
</style>
