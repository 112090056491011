import { get } from "lodash";

export const parseLanguageBasedLabel = (
  labels: any,
  key: string,
  lang: string,
  defaultText: string
) => {
  const labelOptions = get(labels, key, {
    enabled: false,
    config: [],
  });
  if (!Array.isArray(labelOptions.config)) {
    return defaultText;
  }
  const fallbackLabel =
    labelOptions.config.find((mapping: any) => mapping.lang === "default")?.label || defaultText;
  if (labelOptions.enabled) {
    return (
      labelOptions.config.find((mapping: any) => mapping.lang === lang)?.label || fallbackLabel
    );
  }
  return defaultText;
};
