var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.message.data.content[0].buttons),function(button,buttonIndex){return _c('div',{key:`button-${buttonIndex}`},[(
        !((button.type && button.type === 'website') || button.url) || _vm.isDisabledButton(button)
      )?_c('el-button',{staticClass:"message-bubble-button",class:{ 'blue-button': _vm.buttonOutline === 'primary' },staticStyle:{"width":"100%","margin":"0 0 10px 0","white-space":"normal"},style:({
        fontFamily: _vm.settings.font,
      }),attrs:{"plain":"","size":"small","type":_vm.buttonOutline,"disabled":_vm.isDisabledButton(button)},domProps:{"innerHTML":_vm._s(button.text)},on:{"click":function($event){return _vm.onClick(button)}}}):_c('a',{attrs:{"href":_vm.validUrl(button.url),"target":button.popup ?? '_blank'}},[_c('el-button',{staticClass:"message-bubble-button",class:{ 'blue-button': _vm.buttonOutline === 'primary' },staticStyle:{"width":"100%","margin":"0 0 10px 0","white-space":"normal"},style:({
          fontFamily: _vm.settings.font,
        }),attrs:{"plain":"","size":"small","type":_vm.buttonOutline},domProps:{"innerHTML":_vm._s(button.text)},on:{"click":function($event){return _vm.onClick(button)}}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }