<template>
  <div>
    <p>
      <strong>{{ value }}</strong>
    </p>
    <div style="display: flex">
      <img
        v-if="isImageFile(value)"
        :src="preview"
        style="min-width: 180px; max-height: 150px; width: 200px"
      />
      <i
        v-if="!isImageFile(value)"
        class="el-icon-document"
        style="font-size: 40px; padding: 20px 50px"
      />

      <!-- errors -->
      <template>
        <ul style="list-style-type: none; padding: 0; margin: 0 10px">
          <template v-if="fileNotValidForScan">
            <li style="font-size: 12px; margin: 0 0 5px 0; text-align: left">
              <div style="display: flex; align-items: center">
                <i style="margin-right: 5px" class="el-icon-warning-outline"></i>
                File is not valid for scan.
              </div>
            </li>
          </template>
          <template v-else-if="isBusy">
            <li style="font-size: 12px; margin: 0 0 5px 0; text-align: left">
              <div style="display: flex; align-items: center">
                <i style="margin-right: 5px" class="el-icon-loading"></i>
                Pending: Scanning for virus...
              </div>
            </li>
          </template>
          <template v-else-if="isFileContainVirus">
            <li style="font-size: 12px; color: red; margin: 0 0 5px 0; text-align: left">
              <div style="display: flex; align-items: center">
                <i style="margin-right: 5px" class="el-icon-warning-outline"></i>
                Invalid: File contain suspicious malware, please try with other file.
              </div>
            </li>
          </template>
          <template v-else-if="isValid">
            <li style="font-size: 12px; margin: 0 0 5px 0; text-align: left; color: #62c23a">
              <div style="display: flex; align-items: center">
                <i style="margin-right: 5px" class="el-icon-circle-check"></i>
                <span style="word-break: break-word">
                  Success: Please press confirm to continue uploading the file.
                </span>
              </div>
            </li>
          </template>

          <li
            style="
              font-size: 12px;
              color: red;
              margin: 0 0 5px 0;
              text-align: left;
              word-break: break-word;
            "
            v-for="(err, errIndex) in fileUploadErrors"
            :key="`file-upload-error-${errIndex}`"
          >
            <div style="display: flex; align-items: center">
              <i style="margin-right: 5px" class="el-icon-warning-outline"></i>
              {{ err }}
            </div>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { validateFile } from "@/helpers/validateFile";
import axios from "@/helpers/axios";
import _ from "lodash-es";

export default {
  name: "UploaderFile",
  props: ["value", "preview", "file"],
  data() {
    return {
      isBusy: false,
      isFileContainVirus: false,
      fileNotValidForScan: false,
      fileUploadErrors: [],
      fileIsValidForUpload: false,
    };
  },
  methods: {
    isImageFile(url) {
      const imageRegex = new RegExp("(jpg|jpeg)|(png)", "g");
      return imageRegex.test(url);
    },
    scanVirus() {
      if (!this.isBusy) {
        this.isBusy = true;
        this.$emit("update-is-busy", true);
        this.isFileContainVirus = false;
        const formData = new FormData();
        formData.append("file", this.file);
        axios
          .post(`${this.$store.state.server}/api/webchat_virus_scan`, formData)
          .then((response) => {
            const result = _.get(response, "data", {});
            const isSafe = !result.is_infected;
            const noVirusDetected = _.isEmpty(result.viruses);
            if (isSafe && noVirusDetected) {
              this.isFileContainVirus = false;
              this.$emit("update-antivirus-failed", false);
            }
          })
          .catch((err) => {
            console.error(err.message);
            this.isFileContainVirus = false;
            this.$emit("update-antivirus-failed", true);
          })
          .finally(() => {
            this.$emit("update-is-busy", false);
            this.isBusy = false;
          });
      }
    },
  },
  computed: {
    /**
     * @description All webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },

    isValid() {
      const isValid = this.fileIsValidForUpload;
      return isValid;
    },
  },

  created() {
    validateFile(this.file).then(({ isValid, errors }) => {
      this.fileIsValidForUpload = isValid;
      this.fileUploadErrors = !isValid ? errors : [];
    });
  },

  mounted() {
    validateFile(this.file).then(({ isValid, errors }) => {
      this.$emit("update-is-file-type-invalid", !isValid);
      const fileNotValidForScan = !isValid;
      if (fileNotValidForScan) {
        this.fileNotValidForScan = true;
        return;
      }
      this.scanVirus();
    });
  },
};
</script>
