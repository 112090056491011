// https://docs.cobrowse.io/sdk-installation/web
export const initCobrowse = (w: any, c: string) => {
  const p = new Promise((res) => {
    w[c] = {
      client() {
        const scriptEl: any = document.createElement("script");
        scriptEl.src = "https://js.cobrowse.io/CobrowseIO.js";
        scriptEl.async = 1;
        const el: any = document.getElementsByTagName("script")[0];
        el.parentNode.insertBefore(scriptEl, el);
        scriptEl.onload = () => {
          res(w[c]);
        };
        return p;
      },
    };
  });
};
