// @ts-nocheck
import { parseLanguageBasedLabel } from "@/helpers/langLabels";
import _ from "lodash-es";
export const mixin = {
  methods: {
    getLabelFeedbacks(type: string, text: string): string {
      const lang = _.get(this, "message.data.meta.lang", "default");
      const resultWithCurrentLanguage = parseLanguageBasedLabel(
        this.settings.languageBasedLabels,
        type,
        localStorage.getItem("currentLanguage"),
        text
      );
      if (resultWithCurrentLanguage) return resultWithCurrentLanguage;
      return parseLanguageBasedLabel(this.settings.languageBasedLabels, type, lang, text);
    },
  },
};
