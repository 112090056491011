<template>
  <el-dialog
    title="OCR"
    :visible="dialogVisible"
    :modal="false"
    :show-close="false"
    width="50%"
    style="text-align: center"
  >
    <el-upload
      ref="upload"
      :action="$store.state.server + '/api/ocr'"
      list-type="picture-card"
      :file-list="fileList"
      :multiple="false"
      :limit="1"
      :data="options"
      :on-change="takePicture"
    >
      <i class="el-icon-plus" />
    </el-upload>
    <br />
    <el-row>
      <el-button @click="cancel"> Cancel </el-button>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: "WebCamDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    options() {
      return {
        senderId: this.$store.state.uid,
        recipientId: location.hostname,
      };
    },
  },
  methods: {
    takePicture(files) {
      this.$refs.upload.clearFiles();
      this.$emit("close");
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>
