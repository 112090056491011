<template>
  <el-tooltip style="padding: 0; border: none" :content="errorMessage" placement="bottom">
    <i
      :style="{
        display: isVisible ? 'inline' : 'none',
      }"
      :disabled="buttonDisabled"
      class="el-icon-warning error-icon"
      @click="resendMessage()"
      style="
        color: #ff392e;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 5px 10px -10px;
      "
    />
  </el-tooltip>
</template>

<script>
import { FAILED_MESSAGE_ERROR_TEXT } from "@/utils";
export default {
  props: {
    isVisible: Boolean,
    resendMessage: Function,
    isDisabled: Boolean,
    errorMessage: String,
  },
  computed: {
    buttonDisabled() {
      return this.isDisabled || this.errorMessage !== FAILED_MESSAGE_ERROR_TEXT;
    },
  },
};
</script>

<style scoped>
.error-icon::before {
  font-size: 18px;
}
</style>
