<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="25"
    height="25"
    :fill="fill"
    x="0"
    y="0"
    viewBox="0 0 20 20"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg" :fill="fill">
        <path
          d="m10 11.4-3.6-3.6-1.5 1.4 5.1 5 5-5-1.5-1.4z"
          :fill="fill"
          data-original="#000000"
        ></path>
        <path
          d="m10 0c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
          :fill="fill"
          data-original="#000000"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, default: "F000" },
  },
};
</script>

<style></style>
