import _ from "lodash-es";

export default (vue: any) => {
  const synth = window.speechSynthesis;
  let googleVoice: SpeechSynthesisVoice;

  if (synth) {
    synth.onvoiceschanged = function () {
      const voice = _.find(synth.getVoices(), { name: "Google US English" });
      if (voice) googleVoice = voice;
    };

    vue.prototype.$listen = (callback: (message: string, complete?: boolean) => void) => {
      const recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition)();
      recognition.lang = "en-US";
      recognition.interimResults = true;
      recognition.maxAlternatives = 0;

      let final_transcript = "";
      let interim_transcript = "";
      recognition.onresult = function (event: any) {
        if (typeof event.results == "undefined") {
          recognition.onend = null;
          recognition.stop();
          return;
        }

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final_transcript += event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }

        if (interim_transcript) {
          callback(interim_transcript);
          interim_transcript = "";
        }

        if (final_transcript) {
          callback(final_transcript, true);
          final_transcript = "";
        }
      };

      // recognition.onerror = (event: any) => {
      //     console.log(event.error);
      // };

      return recognition;
    };

    vue.prototype.$speak = (text: string) => {
      text = text.replace(/&nbsp;/g, " ");
      text = text.replace(/[^\w ,.]/g, "");

      const utterThis = new SpeechSynthesisUtterance(text);

      if (googleVoice) {
        utterThis.voice = googleVoice;
        utterThis.rate = 1;
      }

      synth.speak(utterThis);
    };
  } else {
    // alert("No speech");
  }
};
