const { marked } = require("marked");
import dompurify from "dompurify";
import { removeWrapedDiv } from "./helpers/removeWrapDiv";

export function markHTML(markdown = "", color: string) {
  const renderer = new marked.Renderer();

  renderer.link = (href: string, title: string, text: string) => {
    return `<a href='${href}' class='keyreply-link' title='Click to open link' style='color: ${color};' target='_blank' on-click="event.stopPropagation();">${text}</a>`;
  };
  const cleanMarkdown = removeWrapedDiv(markdown);
  const html = marked(cleanMarkdown, {
    renderer,
    gfm: true,
    breaks: true,
  });

  dompurify.addHook("afterSanitizeAttributes", function (node) {
    // set non-HTML/MathML links to xlink:show=new
    if (node.hasAttribute("xlink:href") || node.hasAttribute("href")) {
      node.setAttribute("target", "_blank");
    }
  });

  return dompurify.sanitize(html);
}

export function time(timestamp: any) {
  return timestamp;
}

export function capitalize(text: string) {
  return typeof text === "string" && text.charAt(0).toUpperCase() + text.slice(1);
}
