<template>
  <div
    :class="{ 'keyreply-panel': panel }"
    :style="[position, background]"
    class="keyreply-chat-icon"
    @click="activate"
  >
    <img :src="icon" :alt="app.id" />
    <div :style="[fontColor]" class="keyreply-label">
      {{ (app.title || app.id) | capitalize }}
    </div>

    <div class="keyreply-qr">
      <img :src="mobileUrlSrc" :alt="app.id" style="width: 100%; height: 100%" />
    </div>

    <a ref="link" :href="href" style="display: none" target="_blank" />

    <facebook v-if="app.id === 'facebook'" :facebook="app" />
  </div>
</template>
<script>
const bgColors = {
  livechat: "#334433",
  zendesk: "#ffffff",
  skype: "#00AFF0",
  whatsapp: "#30BE2D",
  email: "#2D70E7",
  sms: "#0AD02C",
  phone: "#0AD02C",
  facetime: "#0DD12F",
  telegram: "#2DA5E1",
  facebook: "#0084FF",
  kakao: "#FBDE24",
  line: "#3ACE01",
  snapchat: "#FFFC00",
  wechat: "#1ECE29",
  reddit: "#017AD4",
  twitter: "#2DAAE1",
  hipchat: "#274970",
  slack: "#423843",
  handouts: "#70AD46",
  boutir: "#1CB78D",
  bot: "#E4392B",
  more: "#888888",
};
const fontColors = {
  livechat: "white",
  zendesk: "black",
  skype: "white",
  whatsapp: "white",
  email: "white",
  sms: "white",
  phone: "white",
  facetime: "white",
  telegram: "white",
  facebook: "white",
  kakao: "white",
  line: "white",
  snapchat: "white",
  wechat: "white",
  reddit: "white",
  twitter: "white",
  hipchat: "white",
  slack: "white",
  handouts: "white",
  boutir: "white",
  bot: "white",
  more: "white",
};

import _ from "lodash-es";
import Facebook from "./Facebook.vue";
export default {
  components: {
    Facebook,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    app: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: false,
    };
  },
  computed: {
    mobileUrlSrc() {
      const app = _.get(this, "app", {});
      if (app && typeof app.data === "string" && app.data.includes("https")) {
        return app.data;
      } else {
        return "";
      }
    },
    icon() {
      if (this.app.icon) {
        return this.app.icon;
      } else {
        return `https://files.keyreply.com/files/images/app/${this.app.id}.svg`;
      }
    },
    background() {
      if (this.app.background) {
        return {
          backgroundColor: this.app.background,
        };
      } else {
        return {
          backgroundColor: bgColors[this.app.id],
        };
      }
    },
    fontColor() {
      if (this.app.fontColor) {
        return {
          color: this.app.fontColor,
        };
      } else {
        return {
          color: fontColors[this.app.id],
        };
      }
    },
    position() {
      return {
        bottom: `${72 + this.index * 52}px`,
      };
    },
    href() {
      const data = this.app.data;
      switch (this.app.id) {
        case "boutir":
          return "javascript:__boutirCallback();";
        case "email":
          return `mailto:${data}`;
        case "facebook":
          return `https://m.me/${data}`;
        case "facetime":
          return `facetime-audio:${data}`;
        case "kakao":
          return `http://goto.kakao.com/${data}`;
        case "line":
          return data;
        case "phone":
          return `tel:${data}`;
        case "reddit":
          return `https://www.reddit.com/message/compose/?to=${data}`;
        case "skype":
          return `skype:${this.$device.iOS ? "//" : ""}${data}?chat`;
        case "sms":
          return `sms:${data}`;
        case "telegram":
          return `https://telegram.me/${data}`;
        case "twitter":
          if (this.$device.Mobile) {
            return `twitter://user?screen_name=${data.replace("@", "")}`;
          } else {
            return `https://twitter.com/${data.replace("@", "")}`;
          }
        case "whatsapp":
          if (this.$device.Mobile) {
            return "whatsapp://send/?phone=" + encodeURIComponent(data);
          } else {
            return "https://web.whatsapp.com/send?phone=" + encodeURIComponent(data);
          }
        default:
          return "";
      }
    },
  },
  methods: {
    hidePanel() {
      this.panel = false;
    },
    showPanel() {
      this.panel = true;
    },
    activate(event) {
      // event.stopPropagation()
      // TODO: Track GA
      this.$parent.$children.forEach((icon) => {
        if (icon.hidePanel) {
          icon.hidePanel();
        }
      });

      if (this.hasPannel) {
        if (this.panel) {
          this.panel = false;
        } else {
          this.panel = true;
        }
      } else if (this.app.id === "bot") {
        this.$store.dispatch("START");
        this.$store.dispatch("TOGGLE_CHAT_WINDOW", this.$device.iOSSDK);
      } else if (this.app.id === "facebook") {
        this.$store.dispatch("TOGGLE_FACEBOOK_CHAT_WINDOW");
      } else if (this.app.id === "zendesk") {
        if (window.$zopim) {
          this.$store.dispatch("TOGGLE_ZENDESK_CHAT", true);
        }
      } else if (this.app.id === "link") {
        window.open(this.app.data, "_blank");
      } else {
        if (this.$device.Mobile) {
          window.location = this.href;
        } else {
          this.$refs.link.click();
        }
      }
    },
  },
};
</script>

<style scoped>
.keyreply-launcher,
.keyreply-chat-icon {
  float: left;
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  right: 14px;
  bottom: 20px;
  border-radius: 25px;
}

.keyreply-chat-icon {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05), 0 15px 30px -10px rgba(0, 0, 0, 0.3);

  -webkit-transition: width 0.25s, height 0.25s, bottom 0.25s, box-shadow 0.25s;
  transition: width 0.25s, height 0.25s, bottom 0.25s, box-shadow 0.25s;
}

.keyreply-chat-icon img {
  height: 50px;
  width: 50px;
  display: inline-block;
}

.keyreply-chat-icon.keyreply-panel {
  position: fixed;
  width: 280px;
  height: 280px;
  right: 50% !important;
  top: 50% !important;
  margin-right: -140px;
  margin-top: -140px;
  z-index: 100;
}

.keyreply-chat-icon:not(.keyreply-panel):hover {
  width: 160px;
  z-index: 100;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 30px 40px -10px rgba(0, 0, 0, 0.3);
}

.keyreply-chat-icon:not(.keyreply-panel) .keyreply-qr {
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}

.keyreply-chat-icon .keyreply-qr {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  margin: 40px;
  border-radius: 5px;
  visibility: visible;
  background-size: contain;
  -webkit-transition: visibility 0s linear 0.2s, opacity 0.2s linear 0.2s;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0.2s;
}

.keyreply-label {
  opacity: 0;
  filter: alpha(opacity=0);
  color: white;
  margin-top: 10px;
  /*margin-left: -4px;*/
  left: 50px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.keyreply-chat-icon:hover > .keyreply-label {
  opacity: 1;
  filter: alpha(opacity=100);
}

.keyreply-chat-icon.keyreply-panel > .keyreply-label {
  opacity: 1;
  filter: alpha(opacity=100);
}
</style>
-->

<!-- Css with RTL generated by script -->
<style scoped>
/*@noflip*/
html[dir="ltr"] .keyreply-launcher,
.keyreply-chat-icon {
  float: left;
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  right: 14px;
  bottom: 20px;
  border-radius: 25px;
}
/*@noflip*/
html[dir="rtl"] .keyreply-launcher,
.keyreply-chat-icon {
  float: right;
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 14px;
  bottom: 20px;
  border-radius: 25px;
}
.keyreply-chat-icon {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05), 0 15px 30px -10px rgba(0, 0, 0, 0.3);
  -webkit-transition: width 0.25s, height 0.25s, bottom 0.25s, box-shadow 0.25s;
  transition: width 0.25s, height 0.25s, bottom 0.25s, box-shadow 0.25s;
}
.keyreply-chat-icon img {
  height: 50px;
  width: 50px;
  display: inline-block;
}
/*@noflip*/
html[dir="ltr"] .keyreply-chat-icon.keyreply-panel {
  position: fixed;
  width: 280px;
  height: 280px;
  right: 50% !important;
  top: 50% !important;
  margin-right: -140px;
  margin-top: -140px;
  z-index: 100;
}
/*@noflip*/
html[dir="rtl"] .keyreply-chat-icon.keyreply-panel {
  position: fixed;
  width: 280px;
  height: 280px;
  left: 50% !important;
  top: 50% !important;
  margin-left: -140px;
  margin-top: -140px;
  z-index: 100;
}
.keyreply-chat-icon:not(.keyreply-panel):hover {
  width: 160px;
  z-index: 100;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 30px 40px -10px rgba(0, 0, 0, 0.3);
}
.keyreply-chat-icon:not(.keyreply-panel) .keyreply-qr {
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
/*@noflip*/
html[dir="ltr"] .keyreply-chat-icon .keyreply-qr {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  margin: 40px;
  border-radius: 5px;
  visibility: visible;
  background-size: contain;
  -webkit-transition: visibility 0s linear 0.2s, opacity 0.2s linear 0.2s;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0.2s;
}
/*@noflip*/
html[dir="rtl"] .keyreply-chat-icon .keyreply-qr {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  margin: 40px;
  border-radius: 5px;
  visibility: visible;
  background-size: contain;
  -webkit-transition: visibility 0s linear 0.2s, opacity 0.2s linear 0.2s;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0.2s;
}
/*@noflip*/
html[dir="ltr"] .keyreply-label {
  opacity: 0;
  filter: alpha(opacity=0);
  color: white;
  margin-top: 10px;
  left: 50px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
/*@noflip*/
html[dir="rtl"] .keyreply-label {
  opacity: 0;
  filter: alpha(opacity=0);
  color: white;
  margin-top: 10px;
  right: 50px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.keyreply-chat-icon:hover > .keyreply-label {
  opacity: 1;
  filter: alpha(opacity=100);
}
.keyreply-chat-icon.keyreply-panel > .keyreply-label {
  opacity: 1;
  filter: alpha(opacity=100);
}
</style>
