import _ from "lodash-es";

export function santizeText(text: string): string {
  const textAffectedByMarkdown = _.first(text) === ">"; // considered as block (in markdown)
  let sanitisedText = text;
  if (textAffectedByMarkdown) {
    sanitisedText = `\\${text}`;
  }
  return sanitisedText;
}

export function splitQuickReplies(quickReplies: { data: any; event: string; text: string }[]) {
  if (_.isEmpty(quickReplies)) {
    return [[], []];
  }
  return quickReplies.reduce(
    (
      acc: Array<{ data: any; event: string; text: string }[]>,
      curr: { data: any; event: string; text: string }
    ) => {
      if (curr.event !== "faq_save_prediction_feedback") {
        acc[0].push(curr);
      } else {
        acc[1].push(curr);
      }
      return acc;
    },
    [[], []]
  );
}
