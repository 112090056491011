export interface ExampleTrending {
  example: string;
  published: boolean;
}

export interface ExampleRecord {
  [country: string]: ExampleWithAnswer;
}

export interface ExampleWithAnswer {
  examples: ExampleTrending[];
  answer: string;
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTextButton = (data: string): string => {
  const button = JSON.parse(data);
  return button.text;
};

export const getNodeIdByLanguage = (language: string, examples: string): string | undefined => {
  const exampleRecord: ExampleRecord = JSON.parse(examples);
  return exampleRecord[language]?.answer || "";
};

export const getExampleByLanguage = (language: string, examples: string): string | undefined => {
  const exampleRecord: ExampleRecord = JSON.parse(examples);
  if (exampleRecord[language]) {
    const publishedExample = [...exampleRecord[language].examples].find(
      (example) => example.published
    );
    return publishedExample?.example || "";
  }
  return "";
};

export const getLastActivityDate = (): Date => {
  const lastSeen = localStorage.getItem("_krlastseen");
  return lastSeen && DATE_TYPE_CHECK.includes(typeof lastSeen) ? new Date(lastSeen) : new Date();
};

export const DATE_TYPE_CHECK = ["string", "number", "Date"];

export const NOT_VALID_RESPONSE = ["handover_talk_to_agent", "fallback_too_complex"];
