<template>
  <el-form
    ref="postchatform"
    :model="postchatFormFieldDetails"
    :rules="formRules"
    style="padding: 15px"
  >
    <div class="farewell-text" v-if="postchatFormSettings.farewell.enabled">
      {{ postchatFormSettings.farewell.message }}
    </div>

    <div class="text-field" v-if="postchatFormSettings.rating.enabled">
      {{ postchatFormSettings.rating.question }}
    </div>
    <el-form-item v-if="postchatFormSettings.rating.enabled" prop="rating">
      <el-rate class="star-center" v-model="postchatFormFieldDetails.rating"></el-rate>
    </el-form-item>

    <div class="text-field" v-if="postchatFormSettings.feedback.enabled">
      {{ postchatFormSettings.feedback.question }}
    </div>
    <el-form-item v-if="postchatFormSettings.feedback.enabled" prop="feedback">
      <el-input
        type="textarea"
        placeholder="Please enter feedback"
        :rows="3"
        v-model="postchatFormFieldDetails.feedback"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        size="mini"
        style="width: 100%"
        @click="submitPostChatForm('postchatform')"
        >Submit Feedback</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import _ from "lodash-es";
export default {
  name: "PostChatForm",
  props: ["settings"],
  data() {
    const validateRating = (rule, value, callback) => {
      if (this.postchatFormSettings.rating.optional) {
        callback();
      } else {
        if (value === 0) {
          callback(new Error("Please give a rating"));
        } else {
          callback();
        }
      }
    };

    const validateFeedback = (rule, value, callback) => {
      if (this.postchatFormSettings.feedback.optional) {
        callback();
      } else {
        if (value === "") {
          callback(new Error("Please write feedback"));
        } else {
          callback();
        }
      }
    };

    return {
      postchatFormSettings: {
        farewell: {
          enabled: false,
          message: "",
        },
        rating: {
          enabled: false,
          optional: true,
          question: "",
        },
        feedback: {
          enabled: false,
          optional: true,
          question: "",
        },
      },
      postchatFormFieldDetails: {
        rating: 0,
        feedback: "",
      },
      formRules: {
        rating: [
          {
            required: true,
            validator: validateRating,
            trigger: "blur",
          },
        ],
        feedback: [
          {
            required: true,
            validator: validateFeedback,
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.getPostChatFormSettings();
  },

  methods: {
    capitalize(text) {
      return _.startCase(text);
    },

    getPostChatFormSettings() {
      this.$store
        .dispatch("GET_POSTCHATFORM_SETTINGS")
        .then((result) => {
          if (_.isEmpty(result)) return;

          this.postchatFormSettings = result;
        })
        .catch((error) => {
          this.$emit("setShowPostChatForm", false);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Error getting form settings",
          });
        });
    },

    validate(formName) {
      let isValidForm = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isValidForm = valid;
        }
      });
      return isValidForm;
    },

    async submitPostChatForm(formName) {
      const isValidForm = this.validate(formName);
      if (!isValidForm) return;
      const { rating, feedback } = this.postchatFormFieldDetails;

      //when fields are optional, don't submit form details to server if all fields are empty
      if (rating === 0 && feedback === "") {
        this.$emit("setShowPostChatForm", false);
        this.$emit("clearCache");
        return;
      }

      const form = {
        rating,
        feedback,
      };

      await this.$store.dispatch("LOGOUT");

      this.$store
        .dispatch("SEND_POSTCHATFORM_DETAILS", form)
        .then(async (result) => {
          const success = _.get(result, "success", false);
          if (success) {
            this.$emit("setShowPostChatForm", false);
            this.$emit("clearCache");
          }
        })
        .catch((error) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Error sending form details",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.farewell-text {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 30px;
}

.text-field {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-capitalize {
  text-transform: capitalize;
}

.star-center {
  text-align: center;
}
</style>
