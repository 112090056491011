<template>
  <div>
    <transition name="el-fade-in">
      <div
        v-if="settings.title"
        :class="{
          effect,
          'keyreply-shadow': settings.launcherShadow !== false,
          'keyreply-medium': !$device.mobile && settings.size !== 'large',
          'keyreply-large': !$device.mobile && settings.size === 'large',
        }"
        :style="launcherLogo"
        @click="launcherClicked"
        class="keyreply-launcher"
        id="kr-launcher"
      >
        <Badge />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Badge from "./Badge";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
dayjs.extend(advancedFormat);
export default {
  name: "Launcher",
  data() {
    return {
      effect: "",
    };
  },
  props: ["isLauncherCanClick"],
  components: {
    Badge,
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    }),

    launcherLogo() {
      const color = escape(this.settings.color);
      const svg = `url("data:image/svg+xml;charset=utf8,%3Csvg width='26' height='26' viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cellipse fill='${color}' cx='13' cy='13' rx='12' ry='12'/%3E%3Cpath d='M6.798 15.503l1.453-.92c.617.215 1.29.334 2 .334 2.898 0 5.247-1.996 5.247-4.46 0-2.46-2.35-4.457-5.248-4.457C7.35 6 5 7.996 5 10.458c0 1.446.81 2.73 2.065 3.545l-.503 1.273c-.038.03-.062.076-.062.127 0 .09.074.162.166.162.054 0 .1-.024.132-.062z' stroke='${color}' stroke-width='.2' fill='%23FFF'/%3E%3Cpath d='M20.297 18.97l.04-.065-.578-1.155c1.066-.814 1.737-1.993 1.737-3.305 0-2.455-2.35-4.445-5.248-4.445-2.9 0-5.25 1.99-5.25 4.445s2.35 4.445 5.25 4.445c.838 0 1.63-.167 2.334-.463l1.39.756c.035.05.095.085.163.085.107 0 .194-.085.194-.19 0-.04-.012-.076-.033-.107z' stroke='${color}' stroke-width='.2' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E")`;
      return {
        borderRadius: this.settings.launcherBorderRadius || "50%",
        backgroundColor: this.settings.color,
        backgroundImage: this.settings.botIcon ? `url(${this.settings.botIcon})` : svg,
      };
    },
  },
  watch: {
    settings: {
      handler(after, before) {
        if (!this.$store.state.chatWindow && after && after.openAutomatically === true) {
          const lastOpenStatus = localStorage.getItem("keyreply-lastOpenStatus");
          const lastSeen = dayjs(localStorage.getItem("_krlastseen") || undefined);
          const now = dayjs();

          const isSameDay = now.isSame(lastSeen, "day");

          // session_length is in minutes
          const isSameSession =
            now.diff(lastSeen, "minutes") < this.$store.state.settings.session_length;

          if (lastOpenStatus == "true" && isSameSession) {
            this.launcherClicked();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    launcherClicked() {
      if (!this.$store.getters.webchatUsersIsAtMaxLimit && this.isLauncherCanClick) {
        this.effect = "keyreply-show-effect";
        if (
          this.$parent.numberOfApps === 1 &&
          this.settings.apps.bot &&
          this.settings.apps.bot.enabled
        ) {
          this.$store.dispatch("START");
          this.$store.dispatch("TOGGLE_CHAT_WINDOW", this.$device.iOSSDK);
        } else {
          this.$parent.showIcons = !this.$parent.showIcons;
        }

        setTimeout(() => {
          this.effect = "";
        }, 700);

        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.keyreply-launcher {
  transition: color 0.7s;
  -webkit-transition: color 0.7s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-size: contain;
  cursor: pointer;
  position: static;

  width: 50px;
  height: 50px;

  pointer-events: auto;

  &.keyreply-shadow {
    box-shadow: 0 8px 11px rgba(71, 69, 123, 0.25), 0 2px 4px rgba(71, 69, 123, 0.12);
  }

  &.keyreply-medium {
    width: 64px;
    height: 64px;
    bottom: 32px;
  }

  &.keyreply-large {
    width: 100px;
    height: 100px;
    max-height: 100px;
    max-width: 100px;
    min-height: 50px;
    bottom: 22px;

    @media only screen and (max-width: 380px) {
      width: 75px;
      height: 75px;
      max-height: 75px;
      max-width: 75px;
    }

    @media only screen and (max-width: 320px) {
      width: 50px;
      height: 50px;
      max-height: 50px;
      max-width: 50px;
    }
  }
}

[dir="ltr"] .keyreply-launcher {
  float: left;

  &.keyreply-medium {
    right: 26px;
  }

  &.keyreply-large {
    right: 18px;
  }
}

[dir="rtl"] .keyreply-launcher {
  float: right;

  &.keyreply-medium {
    left: 26px;
  }

  &.keyreply-large {
    left: 18px;
  }
}

.launcher-subtext {
  position: relative;
  top: 52px;
  text-align: center;
  font-size: 0.8em;
  transition: color 0.5s ease;

  &:hover {
    color: #0645ad;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    text-decoration: underline;
  }
}

/* Click Effects */
.keyreply-effect:before {
  position: absolute;
  top: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  content: "";
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
}

[dir="ltr"] .keyreply-effect:before {
  left: 50%;
  margin: -50px 0 0 -50px;
}

[dir="rtl"] .keyreply-effect:before {
  right: 50%;
  margin: -50px -50px 0 0;
}

.keyreply-effect:before,
.keyreply-effect:after {
  box-shadow: 0 0 0 2px rgba(111, 148, 182, 0.6);
}

.keyreply-effect.keyreply-show-effect:before {
  -webkit-animation: keyreply-effect-1 0.5s forwards;
  animation: keyreply-effect-1 0.5s forwards;
}

.keyreply-effect.keyreply-show-effect:after {
  -webkit-animation: keyreply-effect-2 0.5s forwards;
  animation: keyreply-effect-2 0.5s forwards;
}

@-webkit-keyframes keyreply-effect-1 {
  0% {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes keyreply-effect-1 {
  0% {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes keyreply-effect-2 {
  0% {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  50%,
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

@keyframes keyreply-effect-2 {
  0% {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  50%,
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}
</style>
