import { Getter } from "vuex";
import { RootState } from "./types";
import _ from "lodash-es";

export const messages: Getter<RootState, RootState> = (state) => state.messages;
export const trendingTopics: Getter<RootState, RootState> = (state) => state.trendingTopics;
export const currentLanguage: Getter<RootState, RootState> = (state) => state.currentLanguage;
export const chatWindow: Getter<RootState, RootState> = (state) => state.chatWindow;
export const settings: Getter<RootState, RootState> = (state) => state.settings;
export const webviewUrl: Getter<RootState, RootState> = (state) => state.webviewUrl;
export const showWebview: Getter<RootState, RootState> = (state) => state.showWebview;
export const returnToChatButton: Getter<RootState, RootState> = (state) => state.returnToChatButton;
export const showMessagePreview: Getter<RootState, RootState> = (state) => state.showMessagePreview;
export const displayLauncher: Getter<RootState, RootState> = (state, getters) => {
  return state.displayLauncher;
};
export const disableTextInput: Getter<RootState, RootState> = (state, getters) => {
  return state.disableTextInput;
};

export const webchatUsersIsAtMaxLimit: Getter<RootState, RootState> = (state, getters) => {
  return state.webchatUsersIsAtMaxLimit;
};

/**
 * @description A async postback action is happening
 * @param state
 * @return {boolean}
 */
export const isSendingPostback: Getter<RootState, RootState> = (state) => {
  return state.isSendingPostback;
};

export const stickyMenu: Getter<RootState, RootState> = (state) => {
  return state.settings.stickyMenu;
};

export const stickyMenuText: Getter<RootState, RootState> = (state) => {
  let text = "Go to menu";
  state.settings.stickyMenuConfig.stickyMenuLanguages.forEach((item) => {
    if (item.lang === state.settings.stickyMenuLanguage) text = item.value;
  });
  return text;
};

export const isUiBetaEnabled: Getter<RootState, RootState> = (state) => {
  return state.settings.ui_beta;
};

export const isRtlModeEnabled: Getter<RootState, RootState> = (state) => {
  return state.settings.rtlMode;
};

export const stickyMenuLanguage: Getter<RootState, RootState> = (state) => {
  return state.settings.stickyMenuLanguage;
};

export const liveChatAgentName: Getter<RootState, RootState> = (state) => {
  return state.liveChatAgentName;
};
