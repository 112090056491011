<template>
  <div class="keyreply-message-grid">
    <div
      class="keyreply-grid-item"
      v-for="(item, index) in message.data.content[0].images"
      :key="index"
      :style="gridItemStyle"
    >
      <div
        :style="{ backgroundImage: `url(${item})` }"
        @click="$emit('setLightBoxImage', item)"
      ></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash-es";

export default {
  props: ["message", "buttonOutline", "shouldDisableIfInLivechat"],
  data() {
    return {
      test: null,
    };
  },

  computed: {
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },
    isMobileSafari() {
      let ua = window.navigator.userAgent;
      return /(iPad|iPhone|iPod).*WebKit/.test(ua) && !/(CriOS|OPiOS)/.test(ua);
    },
    gridItemStyle() {
      return {
        flexBasis: 100 / this.message.data.content[0].options.column + "%",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.keyreply-message-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 8px;

  .keyreply-grid-item {
    padding: 8px;
    flex-basis: 50%;
    box-sizing: border-box;

    div {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      border-radius: 16px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
}
</style>
