<template>
  <div
    class="file-wrapper"
    :style="{
      justifyContent: botMessage ? 'center' : isUserMessage ? 'flex-end' : 'flex-start',
    }"
  >
    <div
      class="message-wrapper"
      :style="!imageContent ? bubbleStyle : {}"
      :class="{
        'message-bubble-left': !isUserMessage && !imageContent,
        'message-bubble-right': isUserMessage && !imageContent,
        'message-image': imageContent,
      }"
    >
      <!-- image preview in card carousel -->
      <div v-if="message.data.content[0].images || message.data.content[0].image">
        <el-card
          v-for="(item, index) in fileUrls"
          :key="index"
          style="height: fit-content"
          :body-style="{
            padding: '0',
            height: 'fit-content',
            display: 'block',
          }"
        >
          <a class="has-cursor-pointer" @click="$emit('setLightBoxImage', item)">
            <img style="width: 100%" :src="item" />
          </a>
        </el-card>
      </div>
      <div v-else-if="message.data.content[0].files">
        <div v-viewer="$options.viewerOptions" style="cursor: pointer" :style="bubbleStyle">
          <div v-for="(item, index) in fileUrls" :key="index">
            <!-- iframe preview -->
            <!-- <a
              v-if="isNative"
              @click="openFile(item)"
              style="text-decoration: none"
            >
              <i class="el-icon-document" style="font-size: 40px" />
              {{ fileNameFromURL(item) }}
            </a> -->
            <a :href="item" target="_blank" style="text-decoration: none">
              <i class="el-icon-document" style="font-size: 40px" />
              {{ fileNameFromURL(item) }}
            </a>
          </div>
        </div>
      </div>
      <div
        v-else-if="message.data.content[0].videos"
        v-viewer="$options.viewerOptions"
        style="cursor: pointer"
        :style="bubbleStyle"
      >
        <div v-for="(item, index) in fileUrls" :key="index">
          <a v-if="isNative" @click="openFile(item)" style="text-decoration: none">
            <i class="el-icon-video-camera" style="font-size: 40px" />
            {{ fileNameFromURL(item) }}
          </a>
          <a v-else :href="item" target="_blank" style="text-decoration: none">
            <i class="el-icon-video-camera" style="font-size: 40px" />
            {{ fileNameFromURL(item) }}
          </a>
        </div>
      </div>

      <div v-if="!botMessage || (botMessage && !imageContent)" style="text-align: right">
        <i style="font-size: 9px !important" v-html="timestamp(message)"></i>
      </div>
    </div>
    <ResendMessageButton
      :isVisible="isUserMessage && !isMessageSent"
      :resendMessage="resendMessage"
      :isDisabled="isResendButtonDisabled"
      :errorMessage="message.errorMessage"
    />
  </div>
</template>

<script>
import _ from "lodash-es";
import "viewerjs/dist/viewer.css";
import Vue from "vue";
import dayjs from "dayjs";

import ResendMessageButton from "./ResendMessageButton";

export default {
  name: "File",
  props: ["message", "buttonOutline", "isUserMessage"],
  viewerOptions: {
    inline: false,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: false,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: false,
    fullscreen: false,
    keyboard: true,
    // url: "data-source",
  },
  data() {
    return {
      fileUrls: [],
      isResendButtonDisabled: false,
      isMessageSent: true,
    };
  },
  components: {
    ResendMessageButton,
  },
  computed: {
    botMessage() {
      return this.message.type === "reply";
    },
    imageContent() {
      return !!(this.message.data.content[0].images || this.message.data.content[0].image);
    },
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },

    /**
     * @description Styling for left and right bubble
     * @return { fontFamily: string; color: string; background: string; borderColor: string }
     */
    bubbleStyle() {
      const background = _.isEmpty(this.settings.background)
        ? "white"
        : `url('${this.settings.background}')`;
      const left = {
        "--left-bubble-color": this.settings.agentBubbleColor,
        "--background": background,
        fontFamily: this.settings.font,
        color: this.settings.agentTextColor,
        background: this.settings.agentBubbleColor,
        borderColor: this.settings.agentBubbleColor + " transparent transparent transparent",
      };

      const right = {
        fontFamily: this.settings.font,
        "--right-bubble-color": this.settings.userBubbleColor,
        "--background": background,
        color: this.settings.userTextColor,
        background: this.settings.userBubbleColor,
        borderColor: this.settings.userBubbleColor + " transparent transparent transparent",
      };
      return !this.isUserMessage ? left : right;
    },
    isNative() {
      return this.$device.iOSSDK || this.$device.isMobileQuery;
    },
  },
  mounted() {
    this.fetchFileUrl();
  },
  methods: {
    /**
     * @description Get timestamp
     * @param message
     * @return {string}
     */
    timestamp(message) {
      let source = this.isUserMessage ? "You" : this.$store.state.settings.name;
      if (message.type === "agent" && this.$store.state.settings.showAgentName) {
        source = _.get(message, "data.meta.name") || _.get(message, "data.meta.email");
        const organization = this.$store.state.settings.organizationName;
        if (organization) {
          source += ` from ${organization}`;
        }
      }
      return `${source} at ${dayjs(message.timestamp).format("D MMM, hh:mma")}`;
    },
    openFile(url) {
      const payload = {
        event: "$display",
        data: {
          type: "iframe",
          url,
        },
      };
      this.$emit("sendPostback", payload);
    },
    fileNameFromURL(item) {
      if (!item) {
        return "";
      }
      const indexOfQueryString = item.lastIndexOf("?");
      if (indexOfQueryString < 0) {
        // no query string found
        const startIndex = item.lastIndexOf("/") + 1;
        const endIndex = item.length;
        const filename = item.slice(startIndex, endIndex);
        return filename;
      } else {
        const filename = item.slice(item.lastIndexOf("/") + 1, item.lastIndexOf("?"));
        return filename;
      }
    },
    fetchFileUrl() {
      const { images, image, files, videos } = _.get(this.message, "data.content[0]", {});
      const content = images || image || files || videos;
      const contentInArray = Array.isArray(content) ? content : [content];
      _.map(contentInArray, (item, index) => {
        const { url = item, mimeType = "", caption = "" } = item;
        const userOrAgentTypeMessage =
          this.message.type === "agent" || this.message.type === "message";

        if (userOrAgentTypeMessage) {
          this.$store
            .dispatch("FETCH_FILE_URL", url)
            .then((response) => {
              const httpRegex = new RegExp("^(http|https)://");
              const containsHttp = httpRegex.test(response.data);
              if (containsHttp) {
                Vue.set(this.fileUrls, index, response.data);
              } else {
                Vue.set(this.fileUrls, index, item);
              }
            })
            .catch((error) => {
              this.$notify({
                type: "error",
                title: "Error fetching image/file",
                message: "Please inform adminstrator of this error.",
              });
              Vue.set(
                this.fileUrls,
                index,
                "https://via.placeholder.com/468x60?text=Image%20not%20found%20or%20deleted"
              );
            });
        } else {
          Vue.set(this.fileUrls, index, item);
        }
      });
    },
    resendMessage: _.debounce(
      async function () {
        this.$set(this, "isResendButtonDisabled", true);
        await this.$store.dispatch("RESEND_FILE", {
          files: this.fileUrls,
          id: this.message.id,
          timestamp: this.message.timestamp,
        });
      },
      200,
      { leading: true }
    ),
    updateMessageStatus(status) {
      this.$set(this, "isMessageSent", status || status === undefined);
      this.$set(this, "isResendButtonDisabled", false);
    },
  },
  watch: {
    message: function (newVal, oldVal) {
      this.updateMessageStatus(newVal.sent);
    },
  },
};
</script>

<style scoped lang="scss">
.file-wrapper {
  display: flex;
  align-items: center;

  img {
    border-radius: 0px;
  }
}
.message-image {
  position: relative;
  padding: 0px;
  max-width: 70%;
}
</style>
