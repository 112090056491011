<template>
  <div id="ndi-qr"></div>
</template>

<script>
export default {
  name: "MessageSingPass",
  props: {
    init: {
      type: Function,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
